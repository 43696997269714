import React from 'react'
import styled from 'styled-components'
import OSHAform from '../../OSHAform'
import Layout from '../../../components/layouts'
import { Link } from "gatsby"
import Collapsible from "react-collapsible"

function index() {
    return (

        <Layout>

            <div className='container landing-container'>


                {/* <h4 >Workhub is proud to announce our <span style={{fontWeight: 'bold', color: '#f05123'}}>100% free training</span> is coming soon.</h4> */}

                <img className="side-img" alt="Man using laptop" src="/landing/OSHA.png" style={{ marginBottom: '1.5rem' }} />
                <h1 ><strong>OSHA</strong> training, coming soon!</h1>

                <h3 style={{ marginBottom: '1rem' }}>Workhub is excited to announce that <strong>OSHA training</strong> will be part of its catalog of over <span style={{ color: '#4a65a4', padding: '0' }}><strong>60 FREE</strong></span> training courses.</h3>

                <p style={{ marginBottom: '1.5rem', fontSize: '20px' }}>OSHA training will be offered in both <strong>English</strong> and <strong>Spanish</strong> and will include: </p>


                {/* <p style={{ marginBottom: '2.5rem', fontSize: '18px' }}>Mauris fermentum nulla eget ex interdum, et commodo mauris posuere.</p> */}
                <div>



                    {/* GENERAL 30 */}

                    <div className="osha-cards">
                        <img src="/landing/thirty.png" className="hide-top" />
                        <Collapsible
                            trigger={

                                <div style={{ display: 'flex' }}>
                                    <img src="/landing/thirty.png" className="hide-img" />
                                    <h5 style={{ marginTop: '1rem' }}><strong>OSHA</strong> 30-Hour General Industry Training <i className="ti-plus">+</i></h5>
                                </div>
                            }
                            triggerWhenOpen={
                                <div style={{ display: 'flex' }}>
                                    <img className="hide-img" src="/landing/thirty.png" />
                                    <h5 style={{ marginTop: '1rem' }}> <strong>OSHA</strong> 30-Hour General Industry Training   <i className="price-active">-</i></h5>

                                </div>
                            }
                            transitionTime={200}
                        >
                            <div>
                                <ul id="osha-list">
                                    <li>Intro to OSHA</li>
                                    <li>Electrical</li>
                                    <li>PPE</li>
                                    <li>Walking and Working Surfaces </li>
                                    <li>Emergency Action Plans</li>
                                    <li>Managing Safety and Health </li>
                                    <li>Materials Handling </li>
                                    <li>Hazard Communication </li>
                                    <li>Hot Work</li>
                                    <li>Industrial Hygiene   </li>
                                    <li>Hazardous Materials</li>
                                    <li>Ergonomics and Safe Lifting Practices </li>
                                    <li>Compressed Gas Cylinders </li>
                                    <li>Lockout/Tagout </li>
                                    <li>Powered Industrial Vehicles  </li>
                                </ul>
                            </div>
                        </Collapsible>
                    </div>

                    {/* GENERAL TEN */}

                    <div className=" osha-cards">
                        <img src="/landing/ten.png" className="hide-top" />
                        <Collapsible
                            trigger={

                                <div style={{ display: 'flex' }}>
                                    <img src="/landing/ten.png" className="hide-img" />
                                    <h5 style={{ marginTop: '1rem' }}><strong>OSHA</strong> 10-Hour General Industry Training <i className="ti-plus">+</i></h5>
                                </div>
                            }
                            triggerWhenOpen={
                                <div style={{ display: 'flex' }}>
                                    <img className="hide-img" src="/landing/ten.png" />
                                    <h5 style={{ marginTop: '1rem' }}> <strong>OSHA</strong> 10-Hour General Industry Training   <i className="price-active">-</i></h5>

                                </div>
                            }
                            transitionTime={200}
                        >
                            <div>
                                <ul id="osha-list">
                                    <li>Intro to OSHA</li>
                                    <li>Electrical</li>
                                    <li>Emergency Action Plans </li>
                                    <li>Walking and Working Surfaces </li>
                                    <li>Hazard Communication </li>
                                    <li>PPE  </li>
                                    <li>Hazardous Materials  </li>
                                    <li>Materials Handling  </li>
                                    <li>Machine Guarding </li>
                                    <li>Introduction to Industrial Hygiene    </li>
                                    <li>Bloodborne Pathogens </li>
                                    <li>Ergonomics  </li>
                                    <li>Safety and Health Programs  </li>
                                    <li>Fall Protection  </li>
                                </ul>
                            </div>
                        </Collapsible>
                    </div>

                    {/* CONSTRUCTION 30 */}

                    <div className=" osha-cards">
                        <img src="/landing/thirty.png" className="hide-top" />
                        <Collapsible
                            trigger={

                                <div style={{ display: 'flex' }}>
                                    <img src="/landing/thirty.png" className="hide-img" />
                                    <h5 style={{ marginTop: '1rem' }}><strong>OSHA</strong> 30-Hour Construction Training <i className="ti-plus">+</i></h5>
                                </div>
                            }
                            triggerWhenOpen={
                                <div style={{ display: 'flex' }}>
                                    <img className="hide-img" src="/landing/thirty.png" />
                                    <h5 style={{ marginTop: '1rem' }}> <strong>OSHA</strong> 30-Hour Construction Training  <i className="price-active">-</i></h5>

                                </div>
                            }
                            transitionTime={200}
                        >
                            <div>

                                <ul id="osha-list">
                                    <li>Intro to OSHA</li>
                                    <li>Managing Safety and Health </li>
                                    <li>OSHA Focus Four </li>
                                    <ul style={{ marginLeft: '2rem' }} id="ul-letters">
                                        <li>Falls</li>
                                        <li>Electrocution</li>
                                        <li>Struck-by</li>
                                        <li>Caught-in/between</li>
                                    </ul>
                                    <li>Personal Protective and Lifesaving Equipment  </li>
                                    <li>Health Hazards in Construction  </li>
                                    <li>Confined Space Entre   </li>
                                    <li>Cranes, Derricks, Hoists, Elevators, and Conveyors   </li>
                                    <li>Ergonomics  </li>
                                    <li>Excavation  </li>
                                    <li>Fire Protection and Prevention    </li>
                                    <li>Materials Handling, Storage, Use, and Disposal </li>
                                    <li>Motor Vehicles, Mechanized Equipment, and Marine Operations  </li>
                                    <li>Powered Industrial Vehicles </li>
                                    <li>Safety and Health Programs  </li>
                                    <li>Scaffolds  </li>
                                    <li>Steel Erection </li>
                                    <li>Hand and Power Tools </li>
                                    <li>Welding and Cutting </li>
                                    <li>Foundations for Safety Leadership </li>

                                </ul>
                            </div>
                        </Collapsible>
                    </div>

                    {/* CONSTRUCTION 10 */}

                    <div className=" osha-cards">
                        <img src="/landing/ten.png" className="hide-top" />
                        <Collapsible
                            trigger={

                                <div style={{ display: 'flex' }}>
                                    <img src="/landing/ten.png" className="hide-img" />
                                    <h5 style={{ marginTop: '1rem' }}><strong>OSHA</strong> 10-Hour Construction Training <i className="ti-plus">+</i></h5>
                                </div>
                            }
                            triggerWhenOpen={
                                <div style={{ display: 'flex' }}>
                                    <img className="hide-img" src="/landing/ten.png" />
                                    <h5 style={{ marginTop: '1rem' }}> <strong>OSHA</strong> 10-Hour Construction Training <i className="price-active">-</i></h5>

                                </div>
                            }
                            transitionTime={200}
                        >
                            <div>
                                <ul id="osha-list">
                                    <li>Intro to OSHA</li>
                                    <li>OSHA Focus Four </li>
                                    <ul style={{ marginLeft: '2rem' }} id="ul-letters">
                                        <li>Falls</li>
                                        <li>Electrocution</li>
                                        <li>Struck-by</li>
                                        <li>Caught-in/between</li>
                                    </ul>
                                    <li>Personal Protective and Lifesaving Equipment</li>
                                    <li>Health Hazards in Construction </li>
                                    <li>Cranes, Derricks, Hoists, Elevators, and Conveyors</li>
                                    <li>Excavation </li>
                                    <li>Materials Handling, Storage, Use, and Disposal  </li>
                                    <li>Scaffolds </li>
                                    <li>Stairways and Ladders</li>
                                    <li>Hand and Power Tools</li>
                                </ul>
                            </div>
                        </Collapsible>
                    </div>

                    <section>

                        <p>Try out any of Workhub’s free <Link to="/features/training/"><strong>online courses</strong></Link> now, <strong>risk-free</strong>, for fast and efficient on-boarding and training of all your workers. </p>

                        <p>Just like our online courses are going to save you time, money, and effort, the rest of our <strong>full-suite safety compliance software</strong> will do the same.  </p>

                        <p>For <strong>only $3 per user per month</strong>, you can create additional custom courses for your organization plus track certificates, upload your policies, track incidents, conduct safety meetings, and much more.  </p>

                    </section>



                </div>



                <p style={{ marginBottom: '1.5rem' }}><strong>Subscribe for notifications and updates on the release of OSHA training. </strong> <img style={{ height: '20px', width: '20px', marginLeft: '5px', marginBottom: '3px' }} alt="Man using laptop" src="/landing/pencil.png" /></p>
                <OSHAform />

                <section>

                    <p style={{ fontSize: '14px' }}>*Workhub’s OSHA Equivalent Training does not provide a DOL Card.</p>

                    <p style={{ fontSize: '14px' }}>Contrary to common belief, OSHA does not actually certify workers. Courses and trainers are considered OSHA "authorized", and workers can receive course completion affidavits. Most workers do not need an official Department of Labor (DOL) card to remain OSHA compliant - they simply need proper training that will teach them how to stay safe on their worksite. </p>

                    <p style={{ fontSize: '14px' }}>In providing our OSHA training, Workhub follows OSHA’s <a target="blank" href="https://www.osha.gov/sites/default/files/publications/osha2254.pdf">Training Requirements in OSHA Standards</a> which notes “this is a voluntary program and does not meet training requirements for any OSHA standards. Although some states, municipalities, or organizations may require outreach training as a condition of employment, it is not an OSHA requirement.” </p>

                    <p style={{ fontSize: '14px' }}>In situations that require an official Department of Labor OSHA 10-Hour or 30-Hour card, workers can obtain those through <a target="blank" href="https://www.osha.gov/training/outreach">OSHA Outreach Trainers</a>. Workhub is happy to work with outreach trainers to fulfill DOL card requirements where needed.  </p>

                    <p style={{ fontSize: '14px' }}>If you’d like to partner with us as an OSHA Outreach Trainer, please visit our <Link to="/resources/partners/become-a-partner">Become a Partner</Link> page.</p>

                </section>

            </div>
        </Layout>


    )
}

export default index

